import React from 'react';
import { Heading } from '../../components';
import { richText } from '../../util/richText';
import {checkForMatch} from '../../util/wysiwyg_field_utils.js'
import { WYSIWYG_FIELD_NAMES } from '../../util/detailes_form_field_names.js' 
import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const replaceNonStandardBrackets = (content) => {
  return content.replace(/＜/g, '<').replace(/＞/g, '>');
};

const SectionTextMaybe = props => {
  const { textKey = "", text, heading, showAsIngress = false } = props;
  const textClass = showAsIngress ? css.ingress : css.text;

  const isWYSIWYGField = checkForMatch(textKey, WYSIWYG_FIELD_NAMES);

  const content = richText(text, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: '/',
  });

  const sanitizedHtmlContent = replaceNonStandardBrackets(text);

  return text ? (
    <section className={css.sectionText}>
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      ) : null}
      {
        !isWYSIWYGField ? 
          <p className={textClass}>{content}</p>:
          <div dangerouslySetInnerHTML={{__html: sanitizedHtmlContent}}/>
      }      
    </section>
  ) : null;
};

export default SectionTextMaybe;
